<template>
  <header>
    <slot></slot>
    <div class="inner">
      <router-link to="/main" class="logo">
        <!-- <img src="@/assets/svg_icon/logo.svg" alt="UX+ ENM"> -->
        <img src="@/assets/svg_icon/logo_kenm.svg" alt="UX+ KENM">
      </router-link>
      <nav>
        <!-- <router-link to="/about">
          ABOUT
        </router-link> -->
        <router-link to="/creator">
          CREATOR
        </router-link>
        <router-link to="/qna">
          Q&A
        </router-link>
        <!-- <router-link to="/news">
          NEWS
        </router-link>
        <router-link to="/career">
          CAREER
        </router-link> -->
      </nav>
      <a class="nav_trigger"
         :class="{ active: $_Store.active_aside }"
         @click="toggleAsideMenu">
        <span></span>
        <span></span>
      </a>
    </div>
  </header>
</template>
<script>
import Util from "@/utils/Util";
export default {
  name : "HeaderBasic",
  data() {
    return {
      message : "Hello Vue",
    };
  },
  methods : {
    toggleAsideMenu : function () {
      // 메뉴토글 할때
      // 활성화 전역 변수 토글
      this.$_Store.active_aside = !this.$_Store.active_aside;
      
      // body 스크롤 비활성화
      const body = document.body;
      if (this.$_Store.active_aside) {
        Util.addClass(body, 'deactive_scroll');
      } else {
        Util.removeClass(body, 'deactive_scroll');
      }
      
      // 메뉴 스크롤 초기화
      const asideNav = document.querySelector('#aside');
      asideNav.scrollTo({top : 0, left : 0, behavior : 'smooth'});
    }
  }
}
</script>
