import axios from "axios";

// 개발환경 구분
// console.log("process.env : ", process.env);
const is_localhost = process.env.NODE_ENV === "localhost" ? true : false;

// axios 인스턴스 생성
// https://axios-http.com/kr/docs/req_config
const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});

/************************************************
 API 함수들
 *************************************************/

/**
 * 뉴스 리스트
 * @returns {Promise<AxiosResponse<any>>}
 */
const fetchNews = () => {
  const response = instance.get("/v1/news");
  return response;
};

/**
 * Qna 리스트
 * @returns {Promise<AxiosResponse<any>>}
 */
const fetchQna = () => {
  const response = instance.get("/mock/v3/qna");
  return response;
};

/**
 * 크리에이터 리스트
 * @returns {Promise<AxiosResponse<any>>}
 */
const fetchCreator = () => {
  const response = instance.get("/mock/v3/creator");
  return response;
};

/**
 * 채용 지원하기
 * @param param
 * @param url
 * @returns {Promise<AxiosResponse<any>>}
 */
const fetchApplicant = (param) => {
  // https://www.npmjs.com/package/vue-recaptcha-v3
  // 적용으로 인해 캡챠를 안타는 localhost 는 v1 을 태운다.
  let url;
  if (process.env.NODE_ENV === "localhost") {
    url = "/v1/applicant";
  } else {
    // url = '/v2/applicant';
    url = "/v3/applicant";
  }

  const response = instance({
    method: is_localhost ? "GET" : "POST",
    url: url,
    data: param,
    headers: { "Content-Type": "multipart/form-data" },
  });

  return response;
};

/**
 * 동기 호출 예제
 * @returns {Promise<AxiosResponse<any>>}
 */
const fetchUserList = async () => {
  try {
    const response = await instance.get("/users");
    return response;
  } catch (error) {
    console.log(error);
  } finally {
    // 이 블록에는 try 블록에서 일어난 일에 관계없이 무조건 실행될 코드가 위치한다.
  }
};

/**
 * 비동기 호출 예제
 * @returns {Promise<AxiosResponse<any>>}
 */
const fetchTodoList = () => {
  const response = instance.get("/todos");
  return response;
};

export {
  fetchUserList,
  fetchTodoList,
  fetchNews,
  fetchQna,
  fetchCreator,
  fetchApplicant,
};
