<template>
  <aside id="aside" :class="{ active: $_Store.active_aside }">
    <div class="inner">
      <nav>
        <!-- <div class="link">
          <router-link to="/about">
            ABOUT
          </router-link>
        </div> -->
        <div class="link">
          <router-link to="/creator"> CREATOR </router-link>
        </div>
        <div class="link">
          <router-link to="/qna"> Q&A </router-link>
        </div>
        <!-- <div class="link">
          <router-link to="/news">
            NEWS
          </router-link>
        </div>
        <div class="link">
          <router-link to="/career">
            CAREER
          </router-link>
        </div> -->
      </nav>
      <div class="address">
        <div class="head">(주)K ENTERTAINMENT</div>
        <div class="body">
          세종특별자치시 장군면 장척로 517(봉안리 439-7) 청사마을 103동 101호
        </div>
      </div>
    </div>
  </aside>
</template>
<script>
export default {
  name: "AsideBasic",
  data() {
    return {
      message: "Hello Vue",
    };
  },
};
</script>
